
::v-deep {
    .white-space-pre-line {
        white-space: pre-line;
    }
    .line-height-1-3 {
        line-height: 1.3;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }

    tr.table-row-grey {
        background-color: rgba(68, 81, 99, 0.06);
    }
}
