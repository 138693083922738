
::v-deep {
    .v-data-table {
        height: 100%;
    }
    .v-data-table__wrapper {
        max-height: calc(100% - 59px);
    }
    .v-data-footer {
        margin: 0 !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
}
