
::v-deep {
    .word-break-keep-all {
        word-break: keep-all;
    }
    .max-width-0 {
        max-width: 0px;
    }
    tr > *:first-of-type:not(:last-of-type) {
        border-right: 1px solid rgba(0, 0, 0, 0.12);
    }
}
